import React from "react";

const Section = () => {
  return (
    <>
      <div className="section-header" data-scroll-section>
        <div className="section-header-container">
          <div className="section-title">about</div>
          <div className="section-order">/001</div>
        </div>
      </div>
      <div className="divider" data-scroll-section></div>
    </>
  );
};

export default Section;
