import React from "react";

const Social = ({ handleMouseEnter, handleMouseLeave }) => {
  return (
    <>
      <div className="section-header" data-scroll-section>
        <div className="section-header-container">
          <div className="section-title">socials</div>
          <div className="section-order">/004</div>
        </div>
      </div>

      <div className="divider" data-scroll-section></div>

      <section className="socials" data-scroll-section>
        <div className="socials-container">
          <div
            className="socials-id ln"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}>
            <a
              href="https://www.linkedin.com/in/amokrane/"
              target="_blank"
              rel="noreferrer">
              linkedin
            </a>
          </div>
          <div
            className="socials-id ig"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}>
            <a
              href="https://github.com/daikozi"
              target="_blank"
              rel="noreferrer">
              github
            </a>
          </div>
          <div
            className="socials-id tw"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}>
            <a href="mailto:mehdi.amokrane@gmail.com">email</a>
          </div>
        </div>
      </section>

      <div className="divider"></div>
    </>
  );
};

export default Social;
