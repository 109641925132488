import React from "react";
import hero from "../assets/images/portfolio.jpg";

const Hero = () => {
  return (
    <>
      <div className="hero-image" data-scroll-section>
        <div className="hero-image-container" data-scroll data-scroll-speed="3">
          <img src={hero} alt="daikozi with a yellow cap" />
        </div>
      </div>

      <div className="whitespace"></div>
    </>
  );
};

export default Hero;
