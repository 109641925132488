import React from "react";
import project1 from "../assets/images/1.jpg";
import project2 from "../assets/images/2.jpg";
import project3 from "../assets/images/3.jpg";
import project4 from "../assets/images/4.jpg";

const Projects = ({ handleMouseEnter, handleMouseLeave }) => {
  return (
    <>
      <div className="section-header" data-scroll-section>
        <div className="section-header-container">
          <div className="section-title">projects</div>
          <div className="section-order">/002</div>
        </div>
      </div>
      <div className="divider" data-scroll-section></div>
      <section className="project" data-scroll-section>
        <div className="project-container">
          <div>
            <nav className="menu">
              <div
                className="menu__item"
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}>
                <a
                  className="menu__item-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://boldness.web.app/">
                  / BLDNSS (e-Shop)
                </a>
                <img className="menu__item-img" src={project1} alt="" />
                <div className="marquee">
                  <div className="marquee__inner">
                    <span>BLDNSS #</span>
                    <span>BLDNSS #</span>
                    <span>BLDNSS #</span>
                    <span>BLDNSS #</span>
                  </div>
                </div>
              </div>
              <div className="divider"></div>

              <div
                className="menu__item"
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}>
                <a
                  className="menu__item-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://arizona.web.app/">
                  / ARIZONA
                </a>
                <img className="menu__item-img" src={project2} alt="" />
                <div className="marquee">
                  <div className="marquee__inner" aria-hidden="true">
                    <span>ARIZONA</span>
                    <span>ARIZONA</span>
                    <span>ARIZONA</span>
                    <span>ARIZONA</span>
                  </div>
                </div>
              </div>
              <div className="divider"></div>

              <div
                className="menu__item"
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}>
                <a
                  className="menu__item-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://mario-5f987.web.app/">
                  / Super Mario
                </a>
                <img className="menu__item-img" src={project3} alt="" />
                <div className="marquee">
                  <div className="marquee__inner" aria-hidden="true">
                    <span>Super Mario</span>
                    <span>Super Mario</span>
                    <span>Super Mario</span>
                    <span>Super Mario</span>
                  </div>
                </div>
              </div>
              <div className="divider"></div>

              <div
                className="menu__item"
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}>
                <a
                  className="menu__item-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://mensblog.web.app/">
                  / MEN'S BLOG
                </a>
                <img className="menu__item-img" src={project4} alt="" />
                <div className="marquee">
                  <div className="marquee__inner">
                    <span>MEN'S BLOG</span>
                    <span>MEN'S BLOG</span>
                    <span>MEN'S BLOG</span>
                    <span>MEN'S BLOG</span>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
            </nav>
          </div>
        </div>
      </section>
      <div className="whitespace"></div>
    </>
  );
};

export default Projects;
