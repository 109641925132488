import React, { useEffect } from "react";
import LocomotiveScroll from "locomotive-scroll";
import { gsap } from "gsap";

import About from "./components/About";
// import Clients from "./components/Clients";
import Divider from "./components/Divider";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Section from "./components/Section";
import Social from "./components/Social";

import "./App.css";
import Cursor from "./components/Cursor";

function App() {
  useEffect(() => {
    setTimeout(() => {
      new LocomotiveScroll({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        smoothMobile: true,
        inertia: 0.5,
      });
    }, 500);
  });

  const handleCursor = (e) => {
    gsap.to(".cursor__ball--big", 0.4, {
      x: e.pageX - 15,
      y: e.pageY - 15,
    });
    gsap.to(".cursor__ball--small", 0.1, {
      x: e.pageX - 5,
      y: e.pageY - 7,
    });
  };

  const handleMouseEnter = () => {
    gsap.to(".cursor__ball--big", 0.3, {
      scale: 4,
    });
  };

  const handleMouseLeave = () => {
    gsap.to(".cursor__ball--big", 0.3, {
      scale: 1,
    });
  };

  return (
    <div
      className="App"
      data-scroll-container
      onMouseMove={(e) => handleCursor(e)}>
      <Cursor />
      <Navbar
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      <Divider />
      <Header />
      <Hero />
      <Section />
      <About />
      <Projects
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      {/* <Clients /> */}
      <Social
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      <Footer
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
    </div>
  );
}

export default App;
