import React from "react";

const Header = () => {
  return (
    <div className="header" data-scroll-section>
      <div className="header-container">
        <h1>
          Mehdi Amokrane
          <br />
          Front-End
          <br />
          &nbsp;Engineer
        </h1>
      </div>
    </div>
  );
};

export default Header;
