import React from "react";

const About = () => {
  return (
    <>
      <section className="about" data-scroll-section>
        <div className="about-container">
          <p>
            Hi, I'm Daikozi and I'm a Front-End Developer, a Data Scientist and
            a Machine Learning Engineer.
          </p>
          <p>
            I have more than 12 years of experience in applied software
            engineering and data analysis in world class industries such as
            automotive and energy.
          </p>
          <p>
            I would be more than happy to provide you with my expertise and my
            knowledge to help you build your project and reach your targets.
          </p>
        </div>
      </section>
      <div className="whitespace"></div>
    </>
  );
};

export default About;
