import React from "react";

const Footer = ({ handleMouseEnter, handleMouseLeave }) => {
  return (
    <>
      <div className="footer" data-scroll-section>
        <div className="navbar">
          <div className="navbar-container">
            <div
              className="site-title"
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={() => handleMouseLeave()}>
              <a href="mailto:mehdi.amokrane@gmail.com">created by Daikozi</a>
            </div>
            <div className="site-year">48.85344, 2.35002</div>
          </div>
        </div>
      </div>
      <br data-scroll-section />
    </>
  );
};

export default Footer;
