import React from "react";

const Navbar = ({ handleMouseEnter, handleMouseLeave }) => {
  return (
    <div className="navbar" data-scroll-section>
      <div className="navbar-container">
        <div className="site-title">Portfolio by daikozi</div>
        <a
          href="mailto:mehdi.amokrane@gmail.com"
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}>
          <div className="site-type">email</div>
        </a>
        <div className="site-year">2021</div>
      </div>
    </div>
  );
};

export default Navbar;
